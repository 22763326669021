import gql from 'graphql-tag'

export const CREATE_PLACEMENT = gql`
mutation Mutation($content: PlacementContent!) {
  createPlacement(content: $content) {
    code
    data
    message
    success
  }
}
`

export const UPDATE_PLACEMENT = gql`
  mutation Mutation($content: PlacementContent!) {
    updatePlacement(content: $content) {
      code
      data
      message
      success
    }
  }
`

export const DELETE_PLACEMENT = gql`
  mutation Mutation($content: PlacementContent!) {
    deletePlacement(content: $content) {
      code
      data
      message
      success
    }
  }
`
    
export const GET_PLACEMENT_STATUS = gql`
  query Placements_status($filter: String) {
    placements_status(filter: $filter) {
      placement_status_id
      status_descr
      status_color
    }
  }
`
    
export const GET_PLACEMENT_IN_CHANNEL = gql`
  query Placements($filter: String, $limit: Int) {
  placements(filter: $filter, limit: $limit) {
    placement_id
    placement_regions
    placement_name
    placement_filter_domains_blacklist
    placement_purchase_qty
    placement_period_type_id
    placement_status {
      placement_status_id
      status_color
      status_descr
    }
    purchase_type {
      purchase_type_id
      purchase_type_name
      purchase_type_format
      purchase_type_field
    }
    site {
      site_id
    }
    channel_id
    format {
      format_id
      format_name
      format_size_height
      format_size_width
    }
    placement_type_of_delivery
    placement_dates
    total_data {
      clicks
      views
      impressions
    }
  }
}
`